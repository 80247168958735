import axios from 'axios';
import { message } from 'antd';

import { API_URL } from '../env';
import { IAuthParams } from '../types';

const api = axios.create({ baseURL: API_URL });

export async function authenticate(params: IAuthParams): Promise<string> {
  try {
    const res = await api.post('/auth', params);

    return res.data.token;
  } catch (e) {
    message.error(e?.response?.data?.error || e?.message || 'Something went wrong. Please try again.');
    throw e;
  }
}

export async function getCompletion(
  {
    engine,
    prompt,
    maxTokens,
    temperature,
    topP,
  }: {
    engine: string;
    prompt: string;
    maxTokens: number;
    temperature?: number;
    topP?: number;
  },
  jwtToken: string
): Promise<string> {
  try {
    const res = await api.post(
      '/getCompletion',
      { engine, prompt, maxTokens, temperature, topP },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );

    return res.data.choices[0].text;
  } catch (e) {
    message.error(e?.response?.data?.error || e?.message || 'Something went wrong. Please try again.');
    throw e;
  }
}
