import { message } from 'antd';
import { useCallback, useState } from 'react';
// import prettier from 'prettier/standalone';

import { getCompletion } from '../api';
import { useAuth } from './useAuth';

// const prettierSolidity = require('prettier-plugin-solidity');

const MAX_TOKENS = 200;
const MAX_LENGTH = 2049; // max model length is 2049
const MAX_PROMPT_LENGTH = MAX_LENGTH - MAX_TOKENS;
const codeStart = 'pragma';

export function useOpenAiApi() {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);

  const generateContract = useCallback(
    async (prompt: string) => {
      setLoading(true);
      try {
        const baseInput = `This is a solidity contract generator.
      Generate solidity code for ${prompt}.
      ###
      ${codeStart}`;

        let currentPrompt = baseInput;
        let code = '';

        while (!isCorrect(code) && !isAboveMaxLength(currentPrompt)) {
          const res = await getCompletion(
            {
              engine: 'davinci',
              maxTokens: MAX_LENGTH,
              temperature: 0.2,
              topP: 1,
              prompt: currentPrompt,
            },
            token
          );

          code += res;
          code = cleanCode(code);
          currentPrompt = baseInput + code;
          // console.log({ code });
          // console.log({ currentPrompt });
        }

        // console.log(code);
        // console.log(finalizeCode(code.trim()));
        // console.log(prettify(`${codeStart} ${finalizeCode(code.trim())}`));

        const finalizedCode = finalizeCode(code.trim());

        if (finalizedCode) {
          return prettify(`${codeStart} ${finalizedCode}`);
        }

        message.error("That didn't work. Please try again.");
        // throw new Error('Contract generation failed');
        return '';
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  return {
    loading,
    generateContract,
  };
}

function prettify(code: string): string {
  return code;
  // try {
  //   // return prettier.format(code, { parser: 'typescript', plugins: [prettierTs] });
  //   // return prettier.format(code, { parser: 'solidity', plugins: [prettierSolidity] });
  // } catch (e) {
  //   console.error(e);
  //   return code;
  // }
}

function cleanCode(code: string): string {
  return code
    .replace(/\/\/.*\n/g, '')
    .replace(/\/\*.*\*\//gm, '')
    .replace(/\n+/g, '\n');
}

function finalizeCode(code: string): string {
  const lastCurly = code.lastIndexOf('}');
  return code.substring(0, lastCurly + 1);
}

function isCorrect(code: string): boolean {
  const openCurlies = (code.match(/{/g) || []).length;
  const closeCurlies = (code.match(/}/g) || []).length;
  return !!code && openCurlies > 0 && closeCurlies > 0 && openCurlies === closeCurlies;
}

function isAboveMaxLength(string: string): boolean {
  return string.length > MAX_PROMPT_LENGTH;
}
