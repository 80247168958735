import React, { createContext, useCallback, useEffect, useState } from 'react';
import jwt from 'jsonwebtoken';

import { IAuthParams } from '../types';
import { authenticate } from '../api';

export interface IAuthContext {
  isAuthenticated: boolean;
  authenticating: boolean;
  token: string;
  onAuthenticate: (params: IAuthParams) => Promise<void>;
  onLogout: () => void;
}

interface IProps {
  children: React.ReactNode;
}

const USER_STORAGE_KEY = 'user';
const MINUTE_MILLIS = 60 * 1000;

const initialToken = readToken();

export const AuthContext = createContext<IAuthContext>({
  isAuthenticated: !!initialToken,
  authenticating: false,
  token: initialToken,
  onAuthenticate: async () => {},
  onLogout: () => {},
});

export const AuthProvider: React.FC<IProps> = ({ children }: IProps) => {
  const [initialized, setInitialized] = useState(false);
  const [authenticating, setAuthenticating] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(!!initialToken);
  const [token, setToken] = useState(initialToken);

  const onLogout = useCallback(() => {
    localStorage.removeItem(USER_STORAGE_KEY);
    setIsAuthenticated(false);
    setToken('');
  }, []);

  const onAuthenticated = useCallback((_token) => {
    setToken(_token);
    setIsAuthenticated(true);
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(_token));
  }, []);

  const onAuthenticate = useCallback(
    async (params: IAuthParams) => {
      setAuthenticating(true);

      try {
        const _token = await authenticate(params);
        onAuthenticated(_token);
      } catch (e) {
        console.error(e);
      } finally {
        setAuthenticating(false);
      }
    },
    [onAuthenticated]
  );

  useEffect(() => {
    const token = readToken();
    if (token && !isSessionExpired(token)) {
      onAuthenticated(token);
    } else {
      onLogout();
    }

    setInitialized(true);
  }, [onAuthenticated, onLogout]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    function logoutIfSessionExpired() {
      if (token && !isSessionExpired(token)) {
        timeoutId = setTimeout(logoutIfSessionExpired, MINUTE_MILLIS);
      } else {
        onLogout();
      }
    }

    logoutIfSessionExpired();

    return () => clearTimeout(timeoutId);
  }, [onLogout, token]);

  return (
    <AuthContext.Provider value={{ token, isAuthenticated, authenticating, onAuthenticate, onLogout }}>
      {initialized && children}
    </AuthContext.Provider>
  );
};

function readToken(): string {
  const token = JSON.parse(localStorage.getItem(USER_STORAGE_KEY) || 'null');

  if (!isSessionExpired(token)) {
    return token;
  }

  return '';
}

function isSessionExpired(token: string): boolean {
  try {
    const exp = (jwt.decode(token) as any).exp as number;
    const timeToExpiration = new Date(exp * 1000).getTime() - new Date().getTime();

    return timeToExpiration <= MINUTE_MILLIS;
  } catch (e) {
    return true;
  }
}
