import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Input, Typography, Form } from 'antd';
import styled from 'styled-components';

import { useAuth } from '../hooks';
import GeneratorHeader from './GeneratorHeader';

export default function AuthPage() {
  const history = useHistory();
  const { authenticating, isAuthenticated, onAuthenticate } = useAuth();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const handleAuth = useCallback(() => {
    onAuthenticate({ login, password });
  }, [login, onAuthenticate, password]);

  const handleLoginChange = useCallback((e: any) => {
    setLogin(e.target.value);
  }, []);

  const handlePasswordChange = useCallback((e: any) => {
    setPassword(e.target.value);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/app');
    }
  }, [history, isAuthenticated]);

  return (
    <>
      <GeneratorHeader />
      <Container>
        <Form layout="vertical">
          <Typography.Title level={3} className="title">
            Log in to Decrypted
          </Typography.Title>
          <Form.Item name="login" rules={[{ required: true, message: 'Login is required' }]}>
            <Input
              type="text"
              size="large"
              style={{ width: '240px' }}
              placeholder="Login"
              disabled={authenticating}
              value={login}
              onChange={handleLoginChange}
            />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: 'Password is required' }]}>
            <Input
              type="password"
              size="large"
              style={{ width: '240px' }}
              placeholder="Password"
              disabled={authenticating}
              value={password}
              required={true}
              onChange={handlePasswordChange}
            />
          </Form.Item>
          <br />
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={authenticating}
            disabled={!login || !password}
            className="submit-btn"
            onClick={handleAuth}
          >
            Submit
          </Button>
        </Form>
      </Container>
    </>
  );
}

const Container = styled.div`
  height: 100%;
  padding-top: 60px;

  .ant-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ant-col.ant-form-item-control {
    text-align: center;
  }

  .title {
    font-weight: 300;
    margin-bottom: 30px;
    color: white;
  }

  .submit-btn {
    margin-bottom: 24px;
    padding-left: 40px;
    padding-right: 40px;
  }
`;
