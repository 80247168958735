import React from 'react';

import GeneratorHeader from './GeneratorHeader';

const GeneratorMain = React.lazy(() => import('./GeneratorMain'));

export default function GeneratorPage() {
  return (
    <>
      <GeneratorHeader />
      <React.Suspense fallback={''}>
        <GeneratorMain />
      </React.Suspense>
    </>
  );
}
