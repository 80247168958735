import React from 'react';

import { AuthProvider } from './contexts';

interface IProps {
  children: React.ReactNode;
}

export default function Providers({ children }: IProps) {
  return (
    <div>
      <AuthProvider>{children}</AuthProvider>
    </div>
  );
}
