import styled from 'styled-components';
import { Layout, Typography, Button, Row, Col, Image, Space } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import video from '../vid/demo.webm';
import submit from '../img/submit.svg';
import generate from '../img/generate.svg';
import deploy from '../img/deploy.svg';
import chronologo from '../img/cl-logo.svg';
import digilogo from '../img/ds-logo.svg';

import ReactPlayer from 'react-player';
import { purple, blue } from './colors';

const { Content } = Layout;
const { Title, Link } = Typography;

export default function LandingMain() {
  return (
    <Layout>
      <Content className="content-hero" id="home">
        <ContentCustom>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={14}>
              <video autoPlay muted loop width="100%">
                <source src={video} type="video/webm" />
              </video>
            </Col>
            <Col className="hero-text" span={10}>
              <Space direction="vertical" size="large">
                <Title className="h1">Write and deploy solidity contracts in minutes</Title>
                <Title level={3} className="h3" type="secondary">
                  Get started with AI-powered contract creation from a simple summary in plain English
                </Title>
                <Link href="#how-it-works" className="playLink">
                  <Button className="playButton" type="primary" shape="circle" icon={<CaretRightOutlined />} />
                  Watch a quick demo
                </Link>
              </Space>
            </Col>
          </Row>
        </ContentCustom>
      </Content>
      <Content className="diagonal-box" id="features">
        <ContentCustom className="content-diagonal">
          <Title level={3} className="h3-white">
            Decrypted is the simplest way
            <br /> to generate and deploy solidity contracts
          </Title>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <FeatureIcon>
                <img src={submit} alt="submit" />
              </FeatureIcon>
              <Title level={4} className="h4-white">
                Submit
              </Title>
              <Title level={5} className="h5-white">
                Write a short summary of
                <br />
                your contract
              </Title>
            </Col>
            <Col span={8}>
              <FeatureIcon>
                <img src={generate} alt="generate" />
              </FeatureIcon>
              <Title level={4} className="h4-white">
                Generate
              </Title>
              <Title level={5} className="h5-white">
                Watch your contract instantly
                <br /> write itself
              </Title>
            </Col>
            <Col span={8}>
              <FeatureIcon>
                <img src={deploy} alt="deploy" />
              </FeatureIcon>
              <Title level={4} className="h4-white">
                Deploy
              </Title>
              <Title level={5} className="h5-white">
                One-click deploy your contract
                <br /> in minutes
              </Title>
            </Col>
          </Row>
        </ContentCustom>
      </Content>
      <Content className="content-how-it-works" id="how-it-works">
        <ContentCustom>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={10} className="columnLeft">
              <Title level={3} className="h3">
                How it works
              </Title>
              <Title level={5} type="secondary" className="h5">
                Write a short summary directly in the text box, click ‘Submit’, and watch your contract write itself in
                front of your eyes. Iterate, edit your contract with ease and deploy in minutes.
              </Title>
            </Col>
            <Col span={14} className="columnRight">
              <ReactPlayer className="vimeoEmbed" url="https://vimeo.com/577192211" controls loop />
            </Col>
          </Row>
        </ContentCustom>
      </Content>
      <Content className="content-partners">
        <ContentCustom>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} className="logoLeft">
              <a href="https://chronologic.network/" target="_blank" rel="noreferrer">
                <Image width="260px" preview={false} src={chronologo} />
              </a>
            </Col>
            <Col span={12} className="logoRight">
              <a href="https://digistrats.com/" target="_blank" rel="noreferrer">
                <Image width="240px" preview={false} src={digilogo} />
              </a>
            </Col>
          </Row>
        </ContentCustom>
      </Content>
      <Content className="content-cta">
        <ContentCustom>
          <Space direction="vertical" size="large">
            <Title level={3} className="h3">
              Get started with AI-powered contract creation
            </Title>
            <Button className="primaryButton" type="primary" size="large" href="/request" target="_blank">
              Request Access
            </Button>
          </Space>
        </ContentCustom>
      </Content>
    </Layout>
  );
}

const FeatureIcon = styled.div`
  width: 210px;
  margin: auto;
`;

const ContentCustom = styled.div`
  .primaryButton {
    margin-left: 15px;
    background: ${purple};
    border-color: ${purple};
    border-radius: 3px;
    height: 45px;
  }
  .playButton {
    margin-right: 10px;
    background: ${blue};
    border-color: ${blue};
  }
  .playLink {
    font-size: 18px;
    color: ${blue};
  }
  .hero-text {
    padding: 0 60px !important;
    margin-top: 60px;
  }
  .columnLeft {
    padding-right: 90px !important;
  }
  .columnRight {
    padding: 0 50px;
  }
  .vimeoEmbed {
    width: 682px !important;
    height: 564px !important;
    box-shadow: 0 0 15px rgb(0 0 0 / 25%);
  }
  .logoLeft {
    text-align: right;
    padding: 30px 30px 30px 0;
  }
  .logoRight {
    text-align: left;
    padding: 30px 30px 30px 0;
  }
  .h1 {
    font-weight: 400;
    font-size: 48px;
  }
  .h3 {
    font-weight: 300;
  }
  .h3-white {
    color: white;
    padding-bottom: 30px;
    font-weight: 300;
  }
  .h4-white {
    color: white;
    font-weight: 300;
  }
  .h5 {
    font-weight: 300;
  }
  .h5-white {
    font-weight: 300;
    color: rgba(255, 255, 255, 0.65);
  }

  max-width: 1200px;
  margin: 60px auto 0;
`;
