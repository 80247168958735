import styled from 'styled-components';
import { Layout, Typography, Badge } from 'antd';

import logo from '../img/decrypted-logo-white.svg';
import { tangaroa } from '../components/colors';

const { Header } = Layout;
const { Text } = Typography;

export default function GeneratorHeader() {
  return (
    <Layout>
      <HeaderApp>
        <Header className="headerApp">
          <HeaderCustom>
            <Logo>
              <a href="/#home">
                <img src={logo} alt="logo" />
              </a>
            </Logo>
            <ChronoLogic>
              <Text className="p-white">by ChronoLogic</Text>
            </ChronoLogic>
            {/* <Wallet>
              <p className="p-white">Address: 0xf32d…1a9d</p>
              <Badge className="antBadge" color="green" text="Connected: Main" />
            </Wallet> */}
          </HeaderCustom>
        </Header>
      </HeaderApp>
    </Layout>
  );
}

const Logo = styled.div`
  width: 235px;
  padding-bottom: 8px;
`;

const ChronoLogic = styled.div`
  font-size: 1rem;
  padding-top: 24px;
`;

const Wallet = styled.div`
  margin-left: auto;
  text-transform: uppercase;
  text-align: right;
  line-height: 12px;
`;

const HeaderCustom = styled.div`
  .p-white {
    color: rgba(255, 255, 255, 0.65);
  }
  .h5-white {
    font-weight: 300;
    color: white;
    margin-top: 0 !important;
    margin-bottom: 0;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1200px;
  height: 100%;
  margin: auto;
`;

const HeaderApp = styled.div`
  .headerApp {
    background: ${tangaroa};
    position: relative;
  }
`;
