import styled from 'styled-components';
import { Layout, Typography, Menu, Button } from 'antd';
import logo from '../img/decrypted-logo.svg';

import { purple } from './colors';

const { Header } = Layout;
const { Text } = Typography;

export default function LandingHeader() {
  return (
    <Layout>
      <Header>
        <HeaderCustom>
          <Logo>
            <a href="/#home">
              <img src={logo} alt="logo" />
            </a>
          </Logo>
          <ChronoLogic>
            <Text type="secondary">by ChronoLogic</Text>
          </ChronoLogic>
          <Menu theme="light" mode="horizontal">
            <Menu.Item key="1">
              <a href="#home">Home</a>
            </Menu.Item>
            <Menu.Item key="2">
              <a href="#features">Features</a>
            </Menu.Item>
            <Menu.Item key="3">
              <a href="#how-it-works">How it works</a>
            </Menu.Item>
          </Menu>
          <Button className="primaryButton" type="primary" size="large" href="/request" target="_blank">
            Request Access
          </Button>
        </HeaderCustom>
      </Header>
    </Layout>
  );
}

const Logo = styled.div`
  width: 235px;
  padding-bottom: 8px;
`;

const ChronoLogic = styled.div`
  font-size: 1rem;
  padding-top: 24px;
`;

const HeaderCustom = styled.div`
  .primaryButton {
    margin-left: 15px;
    background: ${purple};
    border-color: ${purple};
    border-radius: 3px;
    height: 45px;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1200px;
  height: 100%;
  margin: auto;
`;
