import { createGlobalStyle } from 'styled-components';
import { blue, blackpearl } from './components/colors';

// weird hack to make prettier recognize css in createGlobalStyle and format it
const styled = {
  div: createGlobalStyle,
};

const GlobalStyle = styled.div`
  body,
  html {
    font-family: -apple-system, 'PingFangSC', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.5px;
    box-sizing: border-box;
    scroll-behavior: smooth;
    background-color: ${blackpearl};
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  .ant-layout-header {
    background: white;
    height: 90px;
    line-height: 90px;
    position: fixed;
    z-index: 3;
    width: 100%;
  }

  .ant-layout-content {
    padding: 0 50px;
  }
  .ant-layout-footer {
    background: black;
  }

  .ant-menu-horizontal {
    border-bottom: none;
  }

  .ant-layout-header .ant-menu {
    margin-left: auto;
  }

  .ant-badge-status-text {
    color: rgba(255, 255, 255, 0.65);
    font-size: 14px;
  }
  .ant-badge-status-dot {
    width: 8px;
    height: 8px;
  }

  .diagonal-box {
    background: ${blue};
    transform: skewY(-5deg);
    margin-top: -10rem;
    padding: 60px 0 180px 0;
    text-align: center;
  }
  .content-hero {
    padding-top: 90px;
  }
  .content-diagonal {
    margin: 0 auto;
    transform: skewY(5deg);
  }
  .content-how-it-works {
    z-index: 2;
    padding-bottom: 60px;
    margin-top: -120px;
    background-color: white;
  }
  .content-partners {
    padding-bottom: 60px;
    background-color: white;
  }
  .content-cta {
    padding-bottom: 60px;
    text-align: center;
  }
`;

export default GlobalStyle;
