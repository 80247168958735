import styled from 'styled-components';
import { Layout, Typography, Space, Divider } from 'antd';

import { purple } from './colors';

const { Footer } = Layout;
const { Title, Link } = Typography;

export default function LandingFooter() {
  return (
    <Layout>
      <Footer>
        <FooterCustom>
          <Title level={5} className="h5">
            ChronoLogic
          </Title>
          <Space direction="vertical" size="large">
            <div>
              <Space direction="horizontal" size="large">
                <Link className="footer-link" href="https://chronologic.zendesk.com/hc/en-us" target="_blank">
                  Support
                </Link>
                <Link className="footer-link" href="https://blog.chronologic.network/" target="_blank">
                  Medium
                </Link>
                <Link className="footer-link" href="https://twitter.com/ChronoLogicETH" target="_blank">
                  Twitter
                </Link>
                <Link className="footer-link" href="https://t.me/chronologicnetwork" target="_blank">
                  Telegram
                </Link>
              </Space>
            </div>
            <div>
              <Link className="footer-link" href="https://app.chronologic.network/" target="_blank">
                Chronos &amp; other dApps
              </Link>
            </div>
          </Space>
          <Divider className="dividerFooter" />
          <p className="copyright">© 2021 ChronoLogic</p>
        </FooterCustom>
      </Footer>
    </Layout>
  );
}

const FooterCustom = styled.div`
  .h5 {
    font-weight: 300;
    color: rgba(255, 255, 255, 0.45);
    padding: 25px 0;
  }
  .copyright {
    color: rgba(255, 255, 255, 0.45);
  }
  .footer-link {
    color: white !important;
  }
  .footer-link:focus,
  .footer-link:hover,
  .footer-link a:focus,
  .footer-link a:hover {
    color: ${purple} !important;
  }
  .dividerFooter {
    border-top: 1px solid rgba(255, 255, 255, 0.16);
  }
  max-width: 1200px;
  margin: auto;
  text-align: center;
`;
